import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {IconImageQuery} from "../../../types/graphql-types";

const IconImage: React.FC = () => {
  const data: IconImageQuery = useStaticQuery(graphql`
    query IconImage {
      placeholderImage: file(relativePath: { eq: "icon_Koala01_400x400.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default IconImage
