import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/BackgroundSection"
import MainContent from "../components/atoms/MainContent"
import Paragraph from "../components/atoms/Paragraph"
import Heading from "../components/atoms/Heading";
import IconImage from "../components/atoms/IconImage";
import {css} from "@emotion/core"

const ContainerStyles = css`
  padding: 30px;
`

const ContentsContainerStyles = css`
  display: flex;
  @media(max-width: 767px) {
    display: block;
  }
`

const IconImageStyles = css`
  margin-right: 30px;
  width: 200px;
  height: 200px;
  @media(max-width: 767px) {
    width: 150px;
    height: 150px;
    margin: auto;
  }
`

const ParagraphStyles = css`
  max-width: 600px;
  text-align: left;
  @media(max-width: 767px) {
    margin: auto;
  }
`

const IndexPage = () => (
  <Layout>
    <BackgroundSection>
      <SEO title="mabui labo"/>
      <MainContent>
        <div css={ContainerStyles}>
          <Heading level={1}>mabui labo</Heading>
          <div css={ContentsContainerStyles}>
            <div css={IconImageStyles}>
              <IconImage/>
            </div>
            <div css={ParagraphStyles}>
              <Paragraph>
                フリーランスエンジニアmabuiのポートフォリオサイトへ
                ようこそ！バックエンドをメインに、フロントエンド、
                インフラの開発も行なっています。
              </Paragraph>
              <Paragraph>
                お気軽にTwitterをフォローしてください！ コメントやお仕事依頼は<Link to={"/contact"}>コンタクトフォーム</Link>からどうぞ。
              </Paragraph>
              <Paragraph>
                - Blog: <a href="https://mabui.org/" target="_blank"
                           rel="noopener noreferrer">https://mabui.org/</a><br/>
                - Github: <a href="https://github.com/mabuix" target="_blank"
                             rel="noopener noreferrer">https://github.com/mabuix</a>
              </Paragraph>
            </div>
          </div>
        </div>
      </MainContent>
    </BackgroundSection>
  </Layout>
)

export default IndexPage
